@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Merriweather:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Add these CSS variable definitions */
  --forest-loss: #dc2626;
  /* red-600 for losses */
  --forest-profit: #16a34a;
  /* green-600 for profits */
  --forest-primary: #2563eb;
  /* blue-600 */
  --forest-primary-light: #60a5fa;
  /* blue-400 */
  --forest-primary-dark: #1e40af;
  /* blue-800 */
  --forest-secondary: #7c3aed;
  /* violet-600 */
  --forest-dark: #1f2937;
  /* gray-800 */
  --forest-muted: #6b7280;
  /* gray-500 */
  --forest-neutral: #4b5563;
  /* gray-600 */
}

@layer base {
  html {
    @apply font-sans text-forest-dark bg-forest-light;
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading text-forest-darker;
    font-family: 'Merriweather', serif;
  }

  :focus {
    @apply outline-none ring-2 ring-opacity-50 ring-forest-primary;
  }

  /* Transition defaults */
  .transition-default {
    transition: all 200ms ease-in-out;
  }

  .transition-fast {
    transition: all 150ms ease-in-out;
  }

  .transition-slow {
    transition: all 300ms ease-in-out;
  }
}

@layer components {
  .app-logo {
    @apply h-[40vmin] pointer-events-none;
  }

  .app-logo-spin {
    animation: spin 20s linear infinite;
  }

  .glassmorphism {
    @apply bg-forest-light/95 backdrop-blur-sm;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  .shadow-sm {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .shadow-lg {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }

  .due-diligence-view pre {
    @apply bg-forest-muted p-4 rounded overflow-x-auto;
  }

  .debug-section {
    @apply bg-forest-muted rounded-lg p-6 mb-8;
  }

  .debug-section input[type="text"],
  .debug-section textarea {
    @apply w-full p-2 border border-border-default rounded-md text-sm;
  }

  .debug-section button {
    @apply bg-forest-primary text-forest-light px-5 py-2.5 rounded cursor-pointer transition-default;
  }

  .debug-section button:hover {
    @apply bg-forest-primary-dark;
  }

  .debug-section button:disabled {
    @apply bg-forest-muted cursor-not-allowed text-forest-dark/50;
  }

  /* New financial text utilities */
  .text-profit {
    @apply text-forest-profit;
  }

  .text-loss {
    @apply text-forest-loss;
  }

  .text-neutral {
    @apply text-forest-neutral;
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}